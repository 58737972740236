<template>
  <div class="news-show" v-loading="pageLoading">
    <!-- 面包屑 -->
    <crumbs-custom :arr="TabBar" />
    <div class="cont flex-row">
      <div class="flex-cell left-box">
        <div class="header-show">
          <div class="title font-weight">{{dataCont.ac_title || ''}}</div>
          <div class="info">
              <div class="m-r-15"><i class="iconfont iconlianjiewangzhi"></i> 来源：{{dataCont.ac_pub_source || ''}}</div>
              <div class="m-r-15">|</div>
              <div class="m-r-15"><i class="iconfont iconmore1"></i> 专栏：{{dataCont.column || ''}}</div>
              <div class="m-r-15">|</div>
              <div class="m-r-15"><i class="iconfont iconbanquan"></i> 作者：{{dataCont.ac_pub_by || ''}}</div>
              <div class="m-r-15">|</div>
              <div class="m-r-15"><i class="iconfont iconriqi"></i> 发布日期：{{dataCont.ac_pub_time || ''}}</div>
          </div>
        </div>
        <div class="cont-show" v-html="dataCont.ac_content"></div>
      </div>
      <div class="right-box">
        <div class="">
          <navigation />
        </div>
        <div class="m-t-10">
          <latest-news />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestPost, requestGet } from "@/api/home/index";
import crumbsCustom from '../../components/crumbsCustom.vue';
import navigation from './components/navigation.vue';
import latestNews from './components/latestNews.vue';
export default {
  components: { crumbsCustom, navigation, latestNews },
  name: "Home",
  props: {},
  data() {
    return {
      pageLoading: false,
      dataCont: {},
      id: this.$route.params.id,
      TabBar: []
    };
  },
  mounted() {
    console.log(this.$route.params)
    this.TabBar = [{router: '/newsLists/'+this.$route.params.menuCode+'/'+this.$route.params.menuName, title: this.$route.params.menuName}]
    // this.$store.commit("CRUMBS_CUSTOM", [{title: this.$route.params.menuName, router: '/newsLists/'+this.$route.params.menuCode+'/'+this.$route.params.menuName}]);
    // 加载数据
    l_loadData(this)
  },
  beforeRouteUpdate(to,from,next){
    // this.$store.commit("CRUMBS_CUSTOM", [{title: to.params.menuName, router: '/newsLists/'+to.params.menuCode+'/'+to.params.menuName }]);
    this.TabBar = [{router: '/newsLists/'+to.params.menuCode+'/'+to.params.menuName, title: to.params.menuName}]
    this.id = to.params.id
    next()
    l_loadData(this)
  },
  methods: {

  },
};

// 获取数据
function l_loadData(pg){
  pg.pageLoading = true
  requestGet("api/article/"+pg.id, {})
    .then((res) => {
      pg.dataCont = res.data || {}
      pg.pageLoading = false
    })
    .catch((error) => {
      pg.$message.error(error);
      pg.pageLoading = false
    });
}

</script>
<style lang="scss" scoped>
  .news-show{
    width: 1200px;
    margin: 0 auto;
    .cont{
      .left-box{
        background: #fff;
        padding: 50px;
      }
      .header-show{
          border-bottom: 2px solid #db3e3e;
          margin-bottom: 20px;
        .info{
            color: #aaa;
            font-size: 14px;
            margin: 10px 0;
            div{
                display: inline-block;
            }
            .iconfont{
                color: #db3e3e;
                font-size: 13px;
            }
        }
      }
      .cont-show{
        text-align: left;
        line-height: 25px;
        font-size: 14px;
      }
      .right-box{
        width: 290px;
      }
    }
  }
</style>
